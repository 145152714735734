import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AppProvider from "./components/common/appProvider";
import Logout from "./components/auth/logout";
import SubmitQuestions from "./components/questions/submit-questions";
import CodeSubmission from "./components/code-submission/code-submission";
import CodeQuestions from "./components/code-submission/code-questions";
import TutorialOverview from "./components/code-submission/tutorial-overview.jsx";
import VideoPage from "./components/code-submission/video-page";
import NotFound from "./components/not-found";
import Leaderboard from "./components/leaderboard/leaderboard";
import ChristmasLeaderboard from "./components/leaderboard/christmasLeaderboard";
import AiChallengeLeaderboard from "./components/leaderboard/aiChallengeLeaderboard";
import AiChallengeSubmissionPage from "./components/file-submission/ai-challenge.jsx";
import AiChallengeOverview from "./components/ai_challenge/AiChallengeOverview.jsx";
import MyStatus from "./components/status/my-status";
import ProtectedRoute from "./components/common/protected-route";
import authService from "./services/authService";
import ForgotPassword from "./components/auth/forgot-password";
import ChangePassword from "./components/auth/change-password";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Footer from "./components/footer";
import Auth from "./components/auth/auth";
import ConfirmUser from "./components/auth/confirm-user";
import EntryPage from "./components/entry_page";
import UserProfile from "./components/profile/user_profile";
// import Cookies from './components/cookies';
import TaskOfTheWeek from "./components/task-of-the-week/task-of-the-week";
import TaskArchive from "./components/task-archive/task-archive";
import Task from "./components/task-archive/task";
import TipsTricksAndLinks from "./components/tips_tricks_and_links/tips_tricks_and_links.jsx";
import PythonInstallation from "./components/python-installation/python-installation";
import MultipleChoiceGrid from "./components/questions/multiple-choice";
import Certificate from "./components/certificate/certificate";

import AppBanner from "./components/AppBanner/appBanner";
import Homepage from "./pages/homepage";
import Milestones from "./pages/milestones";
import Programming from "./pages/programming";
import Ethics from "./pages/ethics";
import Tutorials from "./pages/tutorials";
import Practice from "./pages/practice";
import AiSchool from "./pages/aiSchool";
import definition from "./pages/definition";
import memory from "./pages/memory";
import MemoryGame from "./components/memory/MemoryGame";
import MemoryQuiz from "./components/memory/MemoryQuiz";
import MemoryTimeline from "./components/memory/MemoryTimeline";
import concepts from "./pages/concepts";
import IntroPage from "./components/code-submission/intro-page";
import CourseMain from "./components/course/CourseMain";
import CourseContent from "./components/course/CourseContent";
import ConclusionPage from "./components/code-submission/conclusion-page";
import EthicsCourseMain from "./components/ethics/ethicsCourseMain";
import EthcisCourseContent from "./components/ethics/ethcisCourseContent";
import IntroVideo from "./components/ethics/introVideo";

import PioneerWomenQuiz from "./components/pioneer-women/PioneerWomenQuiz";
import PioneerWomenQuizIntro from "./components/pioneer-women/PioneerWomenQuizIntro";
import PioneerWomenIntroPage from "./components/pioneer-women/PioneerWomenIntroPage";
import PioneerWomenTimeline from "./components/pioneer-women/PioneerWomenTimeline";
import PioneerWomenCatalogue from "./components/pioneer-women/MuseumCatalogue.jsx";
// Classroom feature
import VirtualClassroom from "./components/classroom/virtualClassroom";
import NewClassroom from "./components/classroom/newClassroom";
import ClassroomOverview from "./components/classroom/classOverview";
import IntroProgramming from "./pages/introProgramming";
// CodeWeek
import CodeWeekOverview from "./components/code-week/code-week-overview.jsx";
import CodeWeekPaketOne from "./components/code-week/CodeWeekPaketOne.jsx";
import CodeWeekPaketTwo from "./components/code-week/CodeWeekPaketTwo.jsx";
import CodeWeekPaketThree from "./components/code-week/CodeWeekPaketThree.jsx";
import CodeWeekChallenge from "./components/code-week/CodeWeekChallenge.jsx";

class App extends Component {
  render() {
    const user = authService.getCurrentUser();
    return (
      <AppProvider>
        <Route
          render={({ location }) => (
            <React.Fragment>
              {location.pathname !== "/auth" &&
                location.pathname !== "/" &&
                location.pathname !== "/change-password" &&
                location.pathname !== "/confirm-user" &&
                location.pathname !== "/datenschutz" && (
                  <AppBanner
                    role={location.pathname === "/"}
                    path={location.pathname}
                  />
                )}
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={300}
                  classNames="fade"
                >
                  <React.Fragment>
                    <Switch>
                      <Route
                        path="/auth"
                        component={Auth}
                        location={location}
                      />
                      {/* <Route path="/entry-page" component={EntryPage} /> */}
                      <Route path="/logout" component={Logout} />
                      <Route
                        path="/forgot-password"
                        component={ForgotPassword}
                      />
                      <Route
                        path="/change-password"
                        component={ChangePassword}
                      />
                      <Route path="/confirm-user" component={ConfirmUser} />

                      {/* Classroom */}
                      <ProtectedRoute
                        user={user}
                        path="/classroom/new-room"
                        component={NewClassroom}
                      />
                      <ProtectedRoute
                        path="/classroom/overview/code=:code"
                        component={ClassroomOverview}
                      />
                      <ProtectedRoute
                        user={user}
                        path="/classroom"
                        component={VirtualClassroom}
                      />

                      {/* START OF WOHER KOMMT KI */}
                      <Route
                        path="/milestones/pioneer-women/timeline"
                        component={PioneerWomenTimeline}
                      />
                      <Route
                        path="/milestones/pioneer-women/museum-catalog"
                        component={PioneerWomenCatalogue}
                      />
                      <ProtectedRoute
                        path="/milestones/pioneer-women/quiz/id=:id"
                        component={PioneerWomenQuiz}
                      />
                      <Route
                        path="/milestones/pioneer-women/quiz"
                        component={PioneerWomenQuizIntro}
                      />
                      <Route
                        path="/milestones/pioneer-women"
                        component={PioneerWomenIntroPage}
                      />
                      {/* Miletones */}
                      <Route
                        path="/milestones/memory/quiz/chapter=:chapterId/id=:id"
                        component={MemoryQuiz}
                      />
                      <Route
                        path="/milestones/memory/game/chapter=:chapterId"
                        component={MemoryGame}
                      />
                      <Route
                        path="/milestones/memory/timeline"
                        component={MemoryTimeline}
                      />
                      <Route path="/milestones/memory" component={memory} />
                      {/* TODO: uncomment above lines and comment below lines while */}
                      {/* <Route path="/milestones/memory" component={Milestones} /> */}
                      <Route
                        path="/milestones/definition"
                        component={definition}
                      />
                      <Route path="/milestones/concepts" component={concepts} />
                      <Route path="/milestones" component={Milestones} />
                      {/* END OF WOHER KOMMT KI */}

                      {/* Ethics */}
                      <ProtectedRoute
                        path="/ethics/course=:course/chapter=:chapterId/id=:id"
                        component={SubmitQuestions}
                      />
                      <Route
                        path="/ethics/course=:course"
                        component={MultipleChoiceGrid}
                      />
                      <ProtectedRoute
                        path="/ethics/ethically-questionable/coursepath=:coursepath"
                        component={EthcisCourseContent}
                        viewMode={true}
                      />
                      <Route
                        path="/ethics/ethically-questionable/intro-video"
                        component={IntroVideo}
                      />
                      <ProtectedRoute
                        path="/ethics/ethically-questionable/introduction"
                        component={EthicsCourseMain}
                        viewMode={true}
                        intro={true}
                      />
                      <ProtectedRoute
                        path="/ethics/ethically-questionable/paths"
                        component={EthicsCourseMain}
                        viewMode={true}
                        flows={true}
                      />
                      <ProtectedRoute
                        path="/ethics/ethically-questionable/conclusion"
                        component={EthicsCourseMain}
                        viewMode={true}
                        conclusion={true}
                      />
                      <Route
                        path="/ethics"
                        component={Ethics}
                        viewMode={true}
                      />

                      {/* START OF WIE BAUT MAN EINE KI */}
                      {/* Python Intro tutorials */}
                      <ProtectedRoute
                        path="/programming/intro/code-questions/course=:course/id=:id/questionId=:questionId"
                        component={CodeQuestions}
                      />
                      <ProtectedRoute
                        path="/programming/intro/code-submission/course=:course/id=:id"
                        component={CodeSubmission}
                      />
                      <ProtectedRoute
                        path="/programming/intro/chapter-video/course=:course/id=:id"
                        component={VideoPage}
                      />
                      {/* <ProtectedRoute path='/programming/intro/intro/course=:course/id=:id' component={IntroPage} /> Removing for now since no object seems to exist in this path */}
                      <ProtectedRoute
                        path="/programming/intro/course-content/course=:course/id=:id"
                        component={CourseContent}
                      />
                      <ProtectedRoute
                        path="/programming/intro/conclusion-page/course=:course/id=:id"
                        component={ConclusionPage}
                      />
                      <Route
                        path="/programming/intro/course=:course"
                        component={CourseMain}
                      />
                      <Route
                        path="/programming/intro"
                        component={IntroProgramming}
                      />

                      {/* Tutorials */}
                      <ProtectedRoute
                        path="/programming/tutorials/code-questions/course=:course/id=:id/questionId=:questionId"
                        component={CodeQuestions}
                      />
                      <ProtectedRoute
                        path="/programming/tutorials/code-submission/course=:course/id=:id"
                        component={CodeSubmission}
                      />
                      <ProtectedRoute
                        path="/programming/tutorials/chapter-video/course=:course/id=:id"
                        component={VideoPage}
                      />
                      <ProtectedRoute
                        path="/programming/tutorials/introduction/course=:course/id=:id"
                        component={IntroPage}
                      />
                      <ProtectedRoute
                        path="/programming/tutorials/conclusion-page/course=:course/id=:id"
                        component={ConclusionPage}
                      />
                      <Route
                        path="/programming/tutorials/course=:course"
                        component={TutorialOverview}
                      />
                      <Route
                        path="/programming/tutorials"
                        component={Tutorials}
                      />

                      {/* Practice Area */}
                      <ProtectedRoute
                        path="/programming/practice/task-archive/task/code-submission/course=:course/id=:id"
                        component={CodeSubmission}
                      />
                      <ProtectedRoute
                        path="/programming/practice/task-archive/task/conclusion-page/course=:course/id=:id"
                        component={ConclusionPage}
                      />
                      <Route
                        path="/programming/practice/task-archive/task/course=:id"
                        component={Task}
                      />
                      <Route
                        path="/programming/practice/task-archive"
                        component={TaskArchive}
                      />

                      <ProtectedRoute
                        user={user}
                        path="/programming/practice/course-content/course=:course/id=:id"
                        component={CourseContent}
                      />
                      <ProtectedRoute
                        user={user}
                        path="/programming/practice/course=ai_challenge/submission"
                        component={AiChallengeSubmissionPage}
                      />
                      <ProtectedRoute
                        user={user}
                        path="/programming/practice/course=ai_challenge/leaderboard"
                        component={AiChallengeLeaderboard}
                      />
                      <Route
                        path="/programming/practice/course=ai_challenge"
                        component={AiChallengeOverview}
                      />

                      <Route
                        path="/programming/practice"
                        component={Practice}
                      />

                      <Route
                        path="/programming"
                        component={Programming}
                        viewMode={true}
                      />
                      {/* END OF WIE BAUT MAN EINE KI */}

                      <Route path="/school-of-the-year" component={AiSchool} />
                      <ProtectedRoute
                        user={user}
                        path="/leaderboard"
                        component={Leaderboard}
                      />
                      <ProtectedRoute
                        user={user}
                        path="/christmas_leaderboard"
                        component={ChristmasLeaderboard}
                      />
                      <ProtectedRoute path="/my-status" component={MyStatus} />
                      <ProtectedRoute
                        user={user}
                        path="/user-profile"
                        component={UserProfile}
                      />
                      <ProtectedRoute
                        path="/task-of-the-week"
                        component={TaskOfTheWeek}
                      />
                      <ProtectedRoute
                        path="/tips-tricks-links"
                        component={TipsTricksAndLinks}
                      />
                      <ProtectedRoute
                        path="/python-installation"
                        component={PythonInstallation}
                      />
                      <ProtectedRoute
                        path="/certificate"
                        component={Certificate}
                      />

                      {/* CODE WEEK */}
                      {/* <Route
                        path="/code-week/paket1/challenge"
                        render={(props) => (
                          <CodeWeekChallenge
                            year="2024"
                            challenge="input-output"
                          />
                        )}
                      />
                      <Route
                        path="/code-week/paket1"
                        component={CodeWeekPaketOne}
                      />
                      <Route
                        path="/code-week/paket2/challenge"
                        render={(props) => (
                          <CodeWeekChallenge year="2024" challenge="fizzbuzz" />
                        )}
                      />
                      <Route
                        path="/code-week/paket2"
                        component={CodeWeekPaketTwo}
                      />
                      <Route
                        path="/code-week/paket3"
                        component={CodeWeekPaketThree}
                      />
                      <Route path="/code-week" component={CodeWeekOverview} /> */}
                      {/* END OF CODE WEEK */}

                      <Route path="/not-found" component={NotFound} />
                      <Route path="/home" component={Homepage} />
                      <Route path="/" component={EntryPage} viewMode={true} />

                      <Redirect to="/not-found" />
                    </Switch>
                    {location.pathname !== "/auth" &&
                      location.pathname !== "/change-password" &&
                      location.pathname !== "/confirm-user" &&
                      location.pathname !== "/" && <Footer />}
                  </React.Fragment>
                </CSSTransition>
              </TransitionGroup>
              {/*<Cookies />*/}
            </React.Fragment>
          )}
        />
      </AppProvider>
    );
  }
}

export default App;
